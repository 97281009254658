import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import KotlovskiTable from "../../components/tables/KotlovskiTable"
import Img1 from "../../images/tvkotlovski.jpg"
import Head from "../../components/head"

const toplovaljaniKotlovskiLimovi = () => {
  const title = "Toplovaljani kotlovski limovi"
  const images = [
    {
      original: `${Img1}`,
    },
  ]
  return (
    <Layout>
      <Head title={title} />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                showThumbnails={false}
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>TOPLOVALJANI KOTLOVSKI LIMOVI</strong> su proizvodi od
                čelika za opremu pod pritiskom, za povišene temperature denisani
                standardom JUS EN 10028 - 2. <br />
                Namenjeni su za izradu kotlova, rezervoara, sudopera pod
                pritiskom, cevovoda i sličnih proizvoda koji su izloženi
                naprezanju usled povišenog pritiska.
              </p>
              <br />
              <p>
                <strong>Asortiman:</strong>
                <br />
                Kotlovski limovi od ugljeničnog čelika Č 1204 isporučuju se u
                obliku tabli:
              </p>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <ul>
                    <li>debljine od 2-15 mm</li>
                    <li>širine do 1500 mm</li>
                    <li>dužine do 6000 mm</li>
                  </ul>
                </div>
                <div className="column is-full">
                  <ul>
                    <strong>Dozvoljena odstupanja:</strong>
                    <p>
                      Propisuje standard JUS EN 10029 za toplo valjane limove od
                      čelika debljine 2 mm i veće.
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Hemijski sastav</a>
                </li>
              </ul>
            </div>
            <KotlovskiTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default toplovaljaniKotlovskiLimovi
